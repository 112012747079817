<template>
  <router-link
    :to="'/recommendation/product-details?sociolla_id=' + product.id_product + '&soco_id=' + product.id_product_soco"
  >
    <li class="block-product">
      <figure>
        <div class="imgwrap">
          <!-- <img :src="product.image_cover || product.product_images[0].url" alt=""> -->
          <img :src="getProductCoverImage" alt="cover" onerror="this.src='/static/img/default_img_brand.svg';" />
        </div>
        <div
          v-if="product.discount_details && product.discount_details.reduction_type == 'percentage'"
          class="offer-tag"
        >
          {{ product.discount_details.reduction * 100 }}%
        </div>
        <div v-else-if="product.discount && product.discount_type == 'percent'" class="offer-tag">
          {{ product.discount }}%
        </div>
        <div
          v-else-if="
            (product.discount && product.discount_type == 'amount') ||
            (product.discount_details && product.discount_details.reduction_type == 'amount')
          "
          class="offer-tag"
        >
          SALE
        </div>
      </figure>
      <div class="info">
        <span class="brands">{{ product.manufacturer_name || product.manufacture.name }}</span>
        <span class="title">{{ product.product_name || product.name }}</span>
      </div>
      <div class="price">
        <span
          v-if="
            (product.discount_type && product.price_after_discount < product.price) ||
            (product.discount_details &&
              product.discount_details.reduction_type &&
              product.price > product.price_after_discount)
          "
          class="cut-price"
          ><strike>Rp {{ product.price | currency }}</strike></span
        >
        <span
          v-if="product.discount && product.price_after_discount > 0 && product.discount_type == 'percent'"
          class="save-price"
          >SAVE {{ product.discount }}%</span
        >
        <span
          v-else-if="
            product.discount && product.discount_details && product.discount_details.reduction_type == 'percent'
          "
          class="save-price"
          >SAVE -{{ product.discount_details.reduction * 100 }}%</span
        >
        <span
          v-else-if="product.discount && product.price_after_discount > 0 && product.discount_type == 'amount'"
          class="save-price"
          >SAVE -Rp. {{ (product.price - product.price_after_discount) | currency }}</span
        >
        <span
          v-else-if="
            product.discount && product.discount_details && product.discount_details.reduction_type == 'amount'
          "
          class="save-price"
          >SAVE -Rp. {{ product.discount_details.reduction | currency }}</span
        >
        <span v-else class="save-price"></span>
        <span
          v-if="product.price_after_discount < product.price && product.price_after_discount && product.discount_type"
          class="actual-price"
          >Rp {{ product.price_after_discount | currency }}</span
        >
        <span
          v-else-if="
            product.price_after_discount < product.price &&
            product.discount_details &&
            product.discount_details.reduction_type
          "
          class="actual-price"
          >Rp {{ product.price_after_discount | currency }}</span
        >

        <span v-else class="actual-price">Rp {{ product.price | currency }}</span>
      </div>
      <div class="rate">
        <div class="rs" :class="'rs-' + $options.filters.rating(product.rating)">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div class="count">({{ product.total_review ? product.total_review : '0' }})</div>
        </div>
      </div>
    </li>
  </router-link>
</template>

<script>
export default {
  name: 'productCard',
  props: ['product'],
  data() {
    return {
      isRecomendedMaskLoading: false,
      recomendedMask: '',
    };
  },
  computed: {
    getProductCoverImage() {
      if (!this.product) {
        return require('static/img/icons/dummy.svg');
      }
      if (this.product.image_cover) {
        return this.product.image_cover;
      }
      if (this.product.product_images && this.product.product_images[0]) {
        return this.product.product_images[0].url;
      }
      return require('static/img/icons/dummy.svg');
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import 'productCard';
</style>
